<template>
  <el-main>
    <page-title />
    <div style="margin:30px;">
      <span style="font-size:20px;color:blue;padding-right:20px;">{{ empName }}</span>
      <span style="font-size:14px;color:gray">登陆时间：{{ visitAt }}</span>
    </div>
    <div class="partition-area">
      <div class="tabs-inner message-list">
        <el-tabs v-model="activeName">
          <table-inner title="未读消息" :table-data="messageList.list" :page-num="messageList.pageNum" :page-size="messageList.pageSize" :pages="messageList.pages" :page-id="1" :table-footer="false" class="customer-no-border-table" @changePageNum="changePageNum($event)">
            <template slot="btn-inner">
              <el-button type="primary" @click="moreMessage">
                更多
              </el-button>
            </template>
            <template slot="table-bd">
              <el-table stripe :data="messageList.list">
                <el-table-column prop="informationContent" :formatter="utils.isEffective" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="message-item" :class="scope.row.status !== 0 ? 'read': ''" @click="read(scope.row, scope.$index)">
                      <img v-if="scope.row.status === 0" src="../../../assets/images/message.png" class="message-icon">
                      <img v-if="scope.row.status !== 0" class="message-icon" src="../../../assets/images/message-open.png">
                      <div>
                        <a v-if="scope.row.status === 0" href="javascript:;" @click="goPage(scope.row)">
                          <span><p class="show_length"><b>{{ utils.formatHtmlLabel(scope.row.informationContent) }}</b></p>
                            <p style="margin-top: 5px;margin-left: 10px;">
                              <span>发送时间: {{ scope.row.createdAt }}</span>
                              <span style="margin-left: 40px;">消息类型: {{ utils.formatMessageType(Number(scope.row.informationType)) }}</span>
                              <span style="margin-left: 90px;">创建人: {{ scope.row.createdBy }}</span>
                            </p>
                          </span>
                        </a>
                        <a v-if="scope.row.status !== 0" style="opacity: 0.5;" href="javascript:;" @click="goPage(scope.row)">
                          <span><p class="show_length">{{ utils.formatHtmlLabel(scope.row.informationContent) }}</p>
                            <p>
                              <span>  发送时间: {{ scope.row.createdAt }}</span>
                              <span style="margin-left: 40px;">消息类型: {{ utils.formatMessageType(Number(scope.row.informationType)) }}</span>
                              <span style="margin-left: 90px;">创建人: {{ scope.row.createdBy }}</span>
                            </p>
                          </span>
                        </a>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </table-inner>
        </el-tabs>
      </div>
      <div class="tabs-inner">
        <el-tabs v-model="activeName" type="card" @tab-click="getTaskList()">
          <el-tab-pane label="待办事宜" name="wait">
            <ics-task-list-inner :loading="loading.list" :label-name="activeName" :table-list="tableList" @search="getTaskList" />
          </el-tab-pane>
          <el-tab-pane label="已办事宜" name="complete">
            <ics-task-list-inner :loading="loading.list" :label-name="activeName" :table-list="tableList" view-mode @search="getTaskList" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-main>
</template>
<script>
import IcsTaskListInner from './components/task-list-inner'
export default {
  components: { IcsTaskListInner },
  data () {
    return {
      activeName: 'wait',
      processList: [],
      empName: this.$store.state.empName,
      visitAt: this.$store.state.visitAt,
      messageList: {
        pageNum: 1, // 当前页
        pageSize: 3,
        list: [] // 表格数据
      },
      wait: 0,
      complete: 0,
      tableList: {
        list: []
      },
      loading: {
        list: false
      }
    }
  },
  created () {
    this.getHello()
    this.getTaskList()
    this.getMessageList()
  },
  methods: {
    getHello () {
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
            ? '中午好'
            : hour < 18
              ? '下午好'
              : '晚上好'
      this.empName = thisTime + ',' + this.empName
    },
    getTaskList (searchForm) {
      this.loading.list = true
      if (!this.activeName) this.activeName = 'wait'
      let searchData = searchForm
      if (searchData) {
        searchData.pageSize = 10
      } else {
        searchData = {
          pageSize: 10
        }
      }
      this.api.event[this.activeName + 'TodoList'](searchForm).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    changePageNum (val) {
      this.searchForm.pageNum = val.pageNum
      this.$router.push({ query: this.searchForm })
      this.getList(this.searchForm)
    },
    getMessageList () {
      this.api.workbench.pageList({ pageNum: 1,
        pageSize: 3}).then(result => {
        this.messageList = result.data.data
      }).finally(() => {
      })
    },
    read (item, index) {
      this.api.workbench.readById(item.id).then(result => {
        this.messageList.list[index].status = 1
      }).catch(e => {
      })
    },
    goPage (row) {
      this.$router.push({
        name: 'messageDetail',
        query: {
          id: row.id
        }
      })
    },
    moreMessage () {
      this.$router.push({
        name: 'message'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.message-list {
  .read {
    opacity: 0.6;
    background:rgba(246,246,246,0.5);
  }
  .message-item {
    cursor:pointer;
    margin: 3px;
    padding: 10px 5px;
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow:5px 5px 3px #dbdbdb;
    .message-icon {
      vertical-align: middle;
      width: 24px;
    }
    span {
      vertical-align: middle;
      display: inline-block;
      word-wrap: break-word;
      word-break: break-all;
      white-space: pre-wrap !important;
    }
    >div {
      vertical-align: middle;
      display: inline-block;
      width: 80%;
      >a {
        margin-left: 5px;
      }
    }
    >span:nth-child(3) {
      width: 15%;
      text-align: right;
    }
  }

}
.show_length{
  width:500px;
  text-overflow :ellipsis;
  white-space :nowrap;
  overflow : hidden;
}
</style>
